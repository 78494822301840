import { NavLink } from 'react-router-dom';
import QuotientLogo from '../../assets/svg/QuotientLogo';
import classes from './Footer.module.css';

const Footer = () => {
  const customerPortalUrl = `${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}`;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div>
          <div className={classes.logoContainer}>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? classes.navigationElementActive
                  : classes.navigationElement
              }
              to="/"
            >
              <QuotientLogo size={36} color="#FFFFFF" />
            </NavLink>
          </div>
        </div>
        <div className={classes.footerNavContainer}>
          <nav>
            <ul>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes.navigationElementActive
                      : classes.navigationElement
                  }
                  to="/"
                >
                  <div>Home</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes.navigationElementActive
                      : classes.navigationElement
                  }
                  to="/our-products"
                >
                  <div>Our Products</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes.navigationElementActive
                      : classes.navigationElement
                  }
                  to="/get-your-q"
                >
                  <div>Get your Q</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes.navigationElementActive
                      : classes.navigationElement
                  }
                  to="/contact-us"
                >
                  <div>Contact Us</div>
                </NavLink>
              </li>
              <li>
                <a href={customerPortalUrl}>Manage Subscription</a>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes.navigationElementActive
                      : classes.navigationElement
                  }
                  to="/privacy-policy"
                >
                  <div>Privacy Policy</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes.navigationElementActive
                      : classes.navigationElement
                  }
                  to="/uela-agreement"
                >
                  <div>Terms and Conditions</div>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          <div className={classes.footerCredits}>
            <p>© 2023 Quotient. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
